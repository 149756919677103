import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../components/mdx/page";
import styled from "styled-components";
import { FaChessKnight, FaRandom, FaCheckDouble, FaWrench, FaFlask, FaCommentsDollar, FaChartLine, FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { ButtonPrimary, ButtonSecondary } from "../components/widgets/button";
import { SignUpButton } from "../components/widgets/sign-up-button";
import { TopPortfolios } from "../components/widgets/top-portfolios";
import TuringTraderNews from "../components/widgets/tt-news";
import { YouTube } from "../components/widgets/youtube";
import { Quote } from "../components/widgets/quote";
export const TopLine = styled.div`
  height: 0.5rem;
  background: ${props => props.theme.gradients.light};
  margin-left: -${props => props.theme.spacings.normal};
  margin-right: -${props => props.theme.spacings.normal};
  margin-bottom: 1rem;
`;
export const SectionHero = styled.div`
  /*margin-top: -${props => props.theme.spacings.normal};*/
`;
export const SectionColor1 = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;
export const SectionColor2 = styled.div`
  margin-left: -${props => props.theme.spacings.normal};
  margin-right: -${props => props.theme.spacings.normal};
  padding-left: ${props => props.theme.spacings.normal};
  padding-right: ${props => props.theme.spacings.normal};
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background: ${props => props.theme.gradients.light};
`;
export const Subtitle = styled.span`
  font-size: larger;
  color: ${props => props.theme.colors.accent};
`;
export const FlexRow = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 3rem;
`;
export const LargeIcon = styled.div`
  flex: 0 0 4rem;
  font-size: ${props => props.theme.dimensions.largeIcon};
  color: ${props => props.theme.colors.decorative};
`;
export const LargeNum = styled.div`
  flex: 0 0 ${props => props.theme.dimensions.largeIcon};;
  height: ${props => props.theme.dimensions.largeIcon};
  width: ${props => props.theme.dimensions.largeIcon};
  border: 0.25rem solid;
  border-radius: 50%;
  font-size: calc(${props => props.theme.dimensions.largeIcon} - 0.5rem);
  line-height: calc(${props => props.theme.dimensions.largeIcon} - 0.5rem);
  text-align: center;
  color: ${props => props.theme.colors.decorative};
`;
export const FillWidth = styled.div`
  flex: 1;
`;
export const FourFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
`;
export const Field = styled.div`
  border: 1px solid ${props => props.theme.colors.decorative};
  padding: 0.5rem 1rem 0.5rem 1rem;
`;
export const ButtonSpacer = () => <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>;
export const _frontmatter = {};
const layoutProps = {
  TopLine,
  SectionHero,
  SectionColor1,
  SectionColor2,
  Subtitle,
  FlexRow,
  LargeIcon,
  LargeNum,
  FillWidth,
  FourFields,
  Field,
  ButtonSpacer,
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


















    <SectionHero mdxType="SectionHero">
      <TopLine mdxType="TopLine" />
      <div style={{
        "position": "relative",
        "minHeight": "300px"
      }}>
        <div style={{
          "marginLeft": "-1rem",
          "marginRight": "-1rem"
        }}>
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1200px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/bde3b8f2e0a87c0f34480ccabe1cadfb/c1b63/main-image.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "52.33333333333333%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABXElEQVQoz5WRbUvCUBiG9zODgrYZ6EKC/kAQWhT9gCJ1mzid+SGItBf6UmoEBkEf+mBQbXPnTJ04LdJN3XliE8ug15uLw8OBi/s5HAr+EALgEiDEn/3zvPq6fmRSPznEc941AFBag8pjX7i0GBGtHjap73pc8nHjDMkdtneL7ZCEaQExAg6IOFr4LE+vBwCN7jB/+7J12ooWmqyAaB4FU5iT8KKsByQlcoKp6Q3HGblQrTuJcmdJNuZ5RAuIFRGXQZxcC8lqMKuEZI1NP0Xyk+ax2O27pfvexnErlDJYUecyejinhXNqOKdycs3zJb85bSwk8VrBpMaa0RlmK93lPWMmps3xKpNSWElhkhot6LTgldO8jz8zIpqN6ysHTapt2w+mk73ubJ4Z2+UGX6knrsx4uR0rWh6lL4iXrJ0La//mmRqQUX1g2cQGcOGf8d7ccx2XkMmvEh/4FULgDakrBuvDexnRAAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "main image",
                  "title": "main image",
                  "src": "/static/bde3b8f2e0a87c0f34480ccabe1cadfb/c1b63/main-image.png",
                  "srcSet": ["/static/bde3b8f2e0a87c0f34480ccabe1cadfb/5a46d/main-image.png 300w", "/static/bde3b8f2e0a87c0f34480ccabe1cadfb/0a47e/main-image.png 600w", "/static/bde3b8f2e0a87c0f34480ccabe1cadfb/c1b63/main-image.png 1200w"],
                  "sizes": "(max-width: 1200px) 100vw, 1200px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </div>
        <div css={`
    position:absolute;
    top:0;left:0;width:100%;height:100%;
    text-shadow: 0 0 10px white, 0 0 5px white, 0 0 3px white;
  `}>
          <h1 style={{
            "background": "0"
          }}>What if you could achieve long-term portfolio growth with less risk?</h1>
          <p>{`TuringTrader is the no-nonsense way to create diversified DIY investment portfolios. Take control of your financial future with portfolios, methodologies, and algorithms designed to generate steady returns while minimizing losses over the long term.`}</p>
          <SignUpButton mdxType="SignUpButton" /><ButtonSpacer mdxType="ButtonSpacer" /><YouTube url="https://www.youtube.com/embed/S0X_GO0b7Lg" mdxType="YouTube" />
        </div>
      </div>
    </SectionHero>
    <SectionColor1 mdxType="SectionColor1">
      <h2>{`Markets are constantly changing`}</h2>
      <Subtitle mdxType="Subtitle">
        <p><strong parentName="p">{`Your portfolio holdings should too`}</strong></p>
      </Subtitle>
      <p>{`TuringTrader stands for a new era of DIY investing strategies. By rotating your assets on a daily, weekly, or monthly schedule, our portfolios let you take advantage of profitable market opportunities`}{` `}{`- and adapt when the markets change.`}</p>
      <Quote author="Sahn Lam" mdxType="Quote">
        <p>{`Fascinating site. It is simple enough that I don't mind doing it everyday. I actually look forward to 5 o'clock and seeing what your models come up with, especially on Wednesdays. It's actually fun!`}</p>
      </Quote>
    </SectionColor1>
    <SectionColor2 mdxType="SectionColor2">
      <h2>{`Grow your capital with better returns and less risk`}</h2>
      <Subtitle mdxType="Subtitle">
        <p><strong parentName="p">{` It's time to leave the 60/40 portfolio in the rearview mirror `}</strong></p>
      </Subtitle>
      <p>{`Your financial goals don't allow for costly mistakes. Our automated trading strategies help you make smarter, more profitable investments to build a better future. Face lower drawdowns, avoid painful market dips, and make the most of market opportunities with backtested, actively managed portfolios.`}</p>
      <p><strong parentName="p">{`Sleep better at night, thanks to a smoother investment ride.`}</strong></p>
      <FlexRow mdxType="FlexRow">
        <LargeIcon mdxType="LargeIcon"><FaChessKnight mdxType="FaChessKnight" /></LargeIcon>
        <FillWidth mdxType="FillWidth">
          <h3>{`Match your investment goals`}</h3>
          <p>{`There is no one-size-fits-all investment. Your portfolio must match your financial goals, your time-horizon and your appetite for risk.`}</p>
          <p>{`Whether you are accumulating long-term wealth or seeking steady income for retirement: we offer a broad range of Premium portfolios to match your needs.`}</p>
          <p>{`Our suite of All-Stars portfolios is the swiss army knife of investing. These portfolios bundle multiple strategies for even lower volatilities.`}</p>
        </FillWidth>
      </FlexRow>
      <FlexRow mdxType="FlexRow">
        <LargeIcon mdxType="LargeIcon"><FaRandom mdxType="FaRandom" /></LargeIcon>
        <FillWidth mdxType="FillWidth">
          <h3>{`Rotate your assets in time with the market`}</h3>
          <p>{`To beat the markets, you need to adjust your asset allocation when the tide changes. You also need to be able to act before the bears eat your profits.`}</p>
          <p>{`It takes time and practice to master the markets. TuringTrader helps you maximize your long-term success without spending all day looking at charts.`}</p>
          <p>{`We watch the markets so that you don't have to, giving you up-to-date email alerts about any changes to your portfolio holdings.`}</p>
        </FillWidth>
      </FlexRow>
      <FlexRow mdxType="FlexRow">
        <LargeIcon mdxType="LargeIcon"><FaCheckDouble mdxType="FaCheckDouble" /></LargeIcon>
        <FillWidth mdxType="FillWidth">
          <h3>{`Invest with conviction`}</h3>
          <p>{`Investing can be stressful. You need conviction to stay the course and follow an investment strategy without second-guessing it.`}</p>
          <p>{`Our investment strategies are based on quantitative methods and hard-coded rules that don't change based on emotions or the whims of the market.`}</p>
          <p>{`We backtest our strategies in rigorous computer simulations so that you know how well they worked in the past. See how we've tested model portfolios reaching back to 2007.`}</p>
        </FillWidth>
      </FlexRow>
    </SectionColor2>
    <SectionColor1 mdxType="SectionColor1">
      <h2>{`See examples of our best-in-class portfolios`}</h2>
      <p>{`Our portfolios cover a wide array of investment scenarios and trading methodologies. Here are our favorite picks:`}</p>
      <br />
      <TopPortfolios mdxType="TopPortfolios" />
      <br />
      <p>{`In total, we are tracking more than three dozen portfolios on TuringTrader. This gives you fine-grained control over your investments.`}</p>
      <SignUpButton mdxType="SignUpButton" /><ButtonSpacer mdxType="ButtonSpacer" /><ButtonSecondary text="Discover Portfolios" to="/portfolios" mdxType="ButtonSecondary" />
      <br /><br />
    </SectionColor1>
    <SectionColor2 mdxType="SectionColor2">
      <h2>{`Transparent tactical asset allocation`}</h2>
      <FourFields mdxType="FourFields">
        <Field style={{
          "borderStyle": "none solid none none"
        }} mdxType="Field">
          <p>{`We take investing further. Gone are the days of strategic buy-and-hold portfolios and watching your wealth evaporate when markets decline. Our tactical portfolios bring the `}<strong parentName="p">{`same techniques used in hedge funds`}</strong>{` to DIY investors like you.`}</p>
        </Field>
        <Field style={{
          "borderStyle": "none none none none",
          "textAlign": "right"
        }} mdxType="Field">
          <p>{`You're in the driver's seat. With TuringTrader, you can `}<strong parentName="p">{`confidently self-manage your accounts`}</strong>{`, because you'll know exactly what you're invested in and how each portfolio works.`}</p>
        </Field>
      </FourFields>
      <Quote author="Matthew Schell" mdxType="Quote">
        <p>{`Wow, those numbers ARE impressive. I love the addition of the All-Stars portfolio! The new portfolios are exactly what I was looking for.  `}</p>
      </Quote>
      <FourFields mdxType="FourFields">
        <Field style={{
          "borderStyle": "none solid none none"
        }} mdxType="Field">
          <p><strong parentName="p">{`Save hefty fees`}</strong>{`. Typical financial professionals charge about 1% of assets under management per year. For most investors, TuringTrader's subscription fee is only a fraction of that while offering much more value.`}</p>
        </Field>
        <Field style={{
          "borderStyle": "none none none none",
          "textAlign": "right"
        }} mdxType="Field">
          <p>{`Successful investing is not about the highest possible return. It's about `}<strong parentName="p">{`carefully balancing returns and risks`}</strong>{`, and beating the benchmark in good and in bad years. See how our `}<a parentName="p" {...{
              "href": "/portfolios/tt-all-stars-tr/"
            }}>{`most balanced portfolio`}</a>{` performed in the difficult markets of 2008, 2011, 2018, and 2020.`}</p>
        </Field>
      </FourFields>
    </SectionColor2>
    <SectionColor1 mdxType="SectionColor1">
      <h2>{`Portfolios built on expert research`}</h2>
      <Subtitle mdxType="Subtitle">
        <p><strong parentName="p">{` The evolution of our DIY investing journey `}</strong></p>
      </Subtitle>
      <FlexRow mdxType="FlexRow">
        <FillWidth mdxType="FillWidth">
          <p><strong parentName="p">{`In the wake of the 2008 recession`}</strong>{`, we hit the reset button and started researching better investment approaches. Leveraging 20 years of experience in software engineering and quantitative finance, our founder, `}<a parentName="p" {...{
              "href": "https://www.linkedin.com/in/felixbertram/"
            }}>{`Felix Bertram`}</a>{`, built an open-source backtesting engine to model the financial markets.`}</p>
        </FillWidth>
        <LargeIcon mdxType="LargeIcon"><FaWrench mdxType="FaWrench" /></LargeIcon>
      </FlexRow>
      <FlexRow mdxType="FlexRow">
        <LargeIcon mdxType="LargeIcon"><FaFlask mdxType="FaFlask" /></LargeIcon>
        <FillWidth mdxType="FillWidth">
          <p><strong parentName="p">{`It would take more than a lifetime`}</strong>{` of hands-on trading to gather the tremendous insight we've accumulated since 2011. We simulated and analyzed hundreds of tactical portfolios from renowned financial experts to learn what works and know what not to do. Our Premium portfolios expand on this work with our proprietary research for even better results.`}</p>
        </FillWidth>
      </FlexRow>
      <FlexRow mdxType="FlexRow">
        <FillWidth mdxType="FillWidth">
          <p><strong parentName="p">{`We are investors like you`}</strong>{`, and we put our money where our mouth is. We experience our portfolios firsthand by trading them for our own accounts. Based on our observations, we continually make improvements and research new ideas.`}</p>
        </FillWidth>
        <LargeIcon mdxType="LargeIcon"><FaCommentsDollar mdxType="FaCommentsDollar" /></LargeIcon>
      </FlexRow>
      <FlexRow mdxType="FlexRow">
        <LargeIcon mdxType="LargeIcon"><FaChartLine mdxType="FaChartLine" /></LargeIcon>
        <FillWidth mdxType="FillWidth">
          <p><strong parentName="p">{`We are driven to improve DIY investing`}</strong>{` with higher returns and lower risks. Our portfolios can easily rival those recommended by financial professionals by taking opportunities when they arise, and reducing exposure when markets turn sour. As a result, our portfolios continuously beat their benchmark without adding undue risk.`}</p>
        </FillWidth>
      </FlexRow>
    </SectionColor1>
    <SectionColor2 mdxType="SectionColor2">
      <h2>{`How investing with TuringTrader works`}</h2>
      <Subtitle mdxType="Subtitle">
        <p><strong parentName="p">{` The 3-step process to using our portfolios `}</strong></p>
      </Subtitle>
      <FlexRow mdxType="FlexRow">
        <LargeNum mdxType="LargeNum">1</LargeNum>
        <FillWidth mdxType="FillWidth">
          <h3>{`Receive Rebalance Alerts`}</h3>
          <p>{`Our portfolios come with daily, weekly, or monthly rebalancing schedules. In any case, we send you an email when it is time to rebalance. With the click of a button, you get the latest asset allocation.`}</p>
        </FillWidth>
      </FlexRow>
      <FlexRow mdxType="FlexRow">
        <LargeNum mdxType="LargeNum">2</LargeNum>
        <FillWidth mdxType="FillWidth">
          <h3>{`Rebalance Your Portfolio`}</h3>
          <p>{`Log into your brokerage account, and compare your current holdings to the new asset allocation. Submit the orders to adjust your positions as required. There is no rush, as we rebalance while the markets are closed and it takes as little as 15 minutes to update your portfolio.`}</p>
        </FillWidth>
      </FlexRow>
      <FlexRow mdxType="FlexRow">
        <LargeNum mdxType="LargeNum">3</LargeNum>
        <FillWidth mdxType="FillWidth">
          <h3>{`Enjoy the Growth`}</h3>
          <p>{`Sit back and let your assets grow. Sleep well at night, knowing that we keep track of the markets and let you know when your portfolio needs your attention. It feels good to take control of your finances.`}</p>
        </FillWidth>
      </FlexRow>
      <br />
      <SignUpButton mdxType="SignUpButton" /><ButtonSpacer mdxType="ButtonSpacer" /><ButtonSecondary text="View All Plans" to="/sign-up/" mdxType="ButtonSecondary" />
      <br /><br />
    </SectionColor2>
    <SectionColor1 mdxType="SectionColor1">
      <h2>{`The markets constantly evolve`}</h2>
      <Subtitle mdxType="Subtitle">
        <p><strong parentName="p">{` We keep researching and innovating `}</strong></p>
      </Subtitle>
      <p>{`Stay up to date on the latest news and resources from TuringTrader:`}</p>
      <TuringTraderNews mdxType="TuringTraderNews" />
    </SectionColor1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      